import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { CaptureQueryParamInSessionStorage } from "../../atoms/CaptureQueryParamInSessionStorage/CaptureQueryParamInSessionStorage";
import { QueryParamCaptureKey } from "../../atoms/CaptureQueryParamInSessionStorage/QueryParamCaptureKey";
import { HeroSection } from "../../molecules/HeroSection/HeroSection";
import { SampleBuffGallery } from "../../molecules/SampleGallery/SampleBuffGallery";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";

export const BuffPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("buffPage.description");
    const title = t("buffPage.title");

    const features = [
        {
            name: t("buffPage.valueProp.benefit1"),
        },
        {
            name: t("buffPage.valueProp.benefit2"),
        },
        {
            name: t("buffPage.valueProp.benefit3"),
        },
        {
            name: t("buffPage.valueProp.benefit4"),
        },
    ];
    const heroTitle = t("buffPage.hero.title");
    const ctaLabel = t("buffPage.create");
    const featureImage = (
        <StaticImage
            className="mx-auto"
            src="./featureImage.png"
            alt={t("buffPage.heroImageAlt")}
            transformOptions={{ fit: "cover" }}
            width={512}
            objectPosition="50% 10%"
            style={{ borderRadius: "4%", overflow: "hidden" }}
            imgStyle={{ borderRadius: "4%" }}
        />
    );
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.Buff}>
            <CaptureQueryParamInSessionStorage queryParamKey={QueryParamCaptureKey.Coupon} />
            <HeroSection
                title={heroTitle}
                features={features}
                ctaLabel={ctaLabel}
                featureImage={featureImage}
                ctaLink={HeadbotRoute.CreateBuff}
            />
            <SampleBuffGallery />
        </ScrollablePageTemplate>
    );
};
